<template>
  <div v-if="userData">
    <b-row>
      <b-col v-if="userData.in_store" md="6">
        <b-card class="h-100 position-relative">
          <p
            class="mb-2 shadow rounded py-1 px-2 text-secondary h4 font-weight-bold fit-content mx-auto"
          >
            بيع مباشر
          </p>

          <p class="h5 mb-1">
            تستطيع من خلال هذه الخدمة أن تبيع أي جهاز للعميل مباشرة من محلك
            الخاص مباشرة بدون أي مصاريف شحن أو ضمان
          </p>
          <p class="h5 mb-1">لكي تضمن نجاح هذه المعاملة عليك الآتي:</p>
          <ol class="h5">
            <li class="mb-1">
              عليك أن تفحص الموبايل بالتطبيق والـتاكد من صحة البيانات
            </li>
            <li class="mb-1">
              أن توضح للعميل أن هذه المعاملة أن الإرجاع سيتم من خلالك
            </li>
            <li class="mb-1">
              في حالة الدفع بالتقسيط عليك إحضار صورة بطاقة صاحب الحساب
            </li>
            <li class="mb-1">
              عليك تسليم العميل الجهاز بعد عملية الدفع بنفس الحالة والمواصفات
              التي تم الإتفاق عليها وأي عدم توافق سوف يتم تجميد الرصيد على حسابك
            </li>
          </ol>

          <div class="mt-auto text-center position-absolute btn-container">
            <b-button
              variant="secondary"
              block
              class="rounded mx-auto"
              to="/Used/Product/Add/In-Store"
              >أضف المنتج</b-button
            >
          </div>
        </b-card>
      </b-col>
      <b-col v-if="userData.portal" md="6">
        <b-card class="h-100 position-relative">
          <p
            class="mb-2 shadow rounded py-1 px-2 text-secondary h4 font-weight-bold fit-content mx-auto"
          >
            بيع منصة
          </p>
          <p class="h5">
            تستطيع من خلال هذه الخدمة أن تعرض أي جهاز للبيع على منصة موبايل مصر
            كما هو متفق عليه في العقود المبرمة بينك وبين شركة موبيتك للحلول
            الإلكترونية
          </p>
          <div class="text-center position-absolute btn-container">
            <b-button
              variant="secondary"
              block
              class="rounded"
              to="/Used/Product/Add/Portal"
              >أضف المنتج</b-button
            >
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

  export default {
   
    data() {
      return {
        userData: null,
      };
    },
    created() {
      this.fetchData();
    },

    methods: {
      fetchData() {
        axios
          .get("profile")
          .then((result) => {
            this.userData = result.data.data;
          })
          .catch((err) => {
            this.errors_back.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back = err.response.data.data;
              }
              this.showDismissibleAlert = true;
            } else {
              this.errors_back = [];
              this.errors_back.push({
                error: "internal server error",
              });
              this.showDismissibleAlert = true;
            }
          });
      },
    },
  };
</script>

<style>
  .card-body {
    position: relative;
  }
  .btn-container {
    bottom: 10px;
    left: 1rem;
    right: 1rem;
  }
</style>
